.card_container_programas {
    border: 1px solid var(--wine);
    margin: 50px 0;
    border-radius: 10px;
    padding: 20px;
}


.card_link h5 {
    color: var(--pink);
    font-family: 'Montserrat-SemiBold';
}


.card_link .bg-secondary {
    float: left;
}

.card_link p {
    color: var(--gold);
}

.card_link .bg-secondary a {
    color: #fff;
    text-decoration: none;
}

@media (max-width:576px) {
    .card_otrosprogramas p {
        font-size: 20px;
    }

    .card_anio h5, .card_anio h6 {
        font-size: 25px;
        font-family: Montserrat-Bold;
    }
}

.card_nombre h5 {
    color: var(--Wine);
    font-family: 'Montserrat-SemiBold';
}

.card_programas h6 {
    color: var(--wine);
}

.card_programas p {
    color: var(--gold);
}

.card_programas .bg-secondary a {
    color: #fff;
    text-decoration: none;
}

@media (max-width:576px) {
    .card_programas p {
        font-size: 20px;
    }

    .card_programas h5, .card_programas h6 {
        font-size: 25px;
        font-family: Montserrat-Bold;
    }
}

.hr-gob::before {
    content: " ";
    width: 35px;
    height: 5px;
    background-color: var(--wine) !important;
    display: block;
    position: absolute;
}
