.card-documents{
	padding: 15px;
	border: 2px solid var(--gray);
	border-radius: 20px;
}
.card-documents h5{
	margin-bottom: 10px;
	font-family: "Montserrat-SemiBold";
}
.card-item{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-top: 1px solid var(--gray);
}