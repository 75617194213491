.art70_title {
    text-align: center;
    padding-bottom: 20px;
}

    .art70_title h5 {
        color: var(--wine);
        font-family: Montserrat-Bold;
    }

.art70-container {
    padding-top: 50px;
    margin-bottom: 50px;
}

.art70_item a {
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
}


.detalle_art70 {
    
    color: #fff;
    font-size: 13px;
    border-radius: 5px;
    padding: 3px 6px;
    text-decoration: none;

}

.detalle_art70 a:nth-child(1) {
    margin-right: 30px;
  
    color: #fff;
    font-size: 13px;
    border-radius: 5px;
    padding: 3px 6px;
    text-decoration: none;
    background-color: var(--green);
}

    .detalle_art70 a:nth-child(2) {
        
        color: #fff;
        font-size: 13px;
        border-radius: 5px;
        padding: 3px 6px;
        text-decoration: none;
        background-color: var(--wine);
    }

    @media (max-width: 576px) {
        .art70-container {
            min-height: 600px;
        }

        .art70_item a {
            padding: 10px 0;
        }
    }

    @media (min-width:577px) and (max-width:820px) {
        .art70-container {
            min-height: 600px;
        }
    }
