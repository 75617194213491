#ultimas_noticias {
    margin-bottom: 30px;
}

#ultimas_noticias .carousel {
    width: 100%;
    height: 500px; 
}

#ultimas_noticias .carousel-inner {
    height: 100%;
}

#ultimas_noticias .carousel-item {
    height: 100%;
}

#ultimas_noticias .img-carousel {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#ultimas_noticias .title_img_carousel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, var(--wine), var(--pink));
    padding: 15px;
}

#ultimas_noticias .title_img_carousel h6 {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    margin: 0;
    font-size: 18px;
    line-height: 1.2;
}

#ultimas_noticias .title_img_carousel p {
    color: var(--whiteBackground);
    font-size: 14px;
    margin: 5px 0 0;
}

#ultimas_noticias .carousel-control-prev,
#ultimas_noticias .carousel-control-next {
    width: 5%;
}

#ultimas_noticias .carousel-control-prev-icon,
#ultimas_noticias .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
}