.card_container_ayudas {
    border: 1px solid var(--wine);
    margin: 50px 0;
    border-radius: 10px;
    padding: 20px;
}

.card_ayudas:not(:last-child) {
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
}

.ayudas_title {
    text-align: center;
    padding-bottom: 20px;
}

.ayudas_title h1 {

    margin-top: 100px;
    color: var(--wine);
    font-family: Montserrat-Bold;
}

.ayudas-container {
    padding-top: 50px;
    margin-bottom: 50px;
}

.card_nombre{

    margin-bottom: 5px;
}

@media (max-width: 576px) {
    .ayudas-container {
        min-height: 600px;
    }

    .ayudas_item a {
        padding: 10px 0;
    }
}

@media (min-width:577px) and (max-width:820px) {
    .ayudas-container {
        min-height: 600px;
    }
}
