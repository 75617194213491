
.tramites.title {
    text-align: center;
    padding-bottom: 20px;
}

.tramites.title h1 {
    color: var(--gold);
    font-family: Montserrat-Bold;
    font-size: 2rem;
}


.h1, .h2 {
    color: var(--wine);
    font-family: Montserrat-Bold;
}


.tramites-container {
    padding: 30px 15px;
    margin-bottom: 30px;
    text-align: center;
    font-style: normal;
    color: #691B32;
}

.tramites_item a {
    color: var(--gold);
    font-size: 16px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.tramites_item a:hover {
    color: var(--wine);
    text-decoration: underline;
}


.container {
    color: #691B32;
}

.accordion {
    margin-top: 5px;
}

.accordion-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #bc955b;
}

.accordion-header {
    font-weight: bold;
    color: var(--wine);
}

.accordion-button {
    background-color: #fff;
    color: var(--wine);
    border: none;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 5px;
}

.accordion-button:hover {
    background-color: var(--gold);
    color: #fff;
}

.accordion-button.collapsed {
    background-color: #fff;
    color: var(--wine);
}

.accordion-button:not(.collapsed) {
    background-color: #bc955b;
    color: var(--wine);
}

.accordion-body {
    font-size: 14px;
    padding: 15px;
    background-color: #fff;
    color: #333;
    line-height: 1.5;
    border-top: 1px solid #ddd;
}


.accordion-body a {
    color: var(--gold);
    font-weight: bold;
    text-decoration: none;
    transition: color 0.2s ease;
}

.accordion-body a:hover {
    color: var(--wine);
    text-decoration: underline;
}

.accordion-button:focus {
    outline: none;
    box-shadow: #691B32;
   
}

.hr-gob::before {
    content: " ";
    width: 35px;
    height: 5px;
    background-color: var(--wine) !important;
    display: block;
    position: absolute;
}

@media (max-width: 576px) {
    .tramites-container {
        min-height: 600px;
    }

    .tramites_item a {
        padding: 10px 0;
    }
}
